import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";
import VideoFilter from "../components/patient-support/videoFilter";

const ExpertsAnswer = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <VideoFilter bindScrollEvent={true}/>
      <FooterBar/>
    </MainContainer>
  );
};

export default ExpertsAnswer;